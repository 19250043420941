

$module: 		'contact';


.#{$module} {
	&__map {
		margin-top: em(-50);
		margin-bottom: em(140);
		padding: 0 em($contentPad);
		
		@include bp(desk-l) {
			padding: 0 em(20);
		}
	}
	
	&__map-wrap {
		height: em(280);
		border-radius: em(20);
		box-shadow: $insetShadow;
		overflow: hidden;
	}
}