
// At top to avoid cascade issues

$module: 		'svg';

.#{$module} {
	&-icon {
		display: block;
		max-width: 100%;
		height: auto;
		pointer-events: none;
	}
}