

$module: 'services';


.service {
	color: $colorSecondary;
}

.#{$module} {
	
	&__title {
		margin: 0 0 em(80);
		text-align: center;
		color: $colorSecondary;
		
		& .beta {
			text-transform: capitalize;
		}
	}
	
	&__items {
		@include display(flex);
		@include flex-wrap(wrap);
	    @include justify-content(space-around);
	    max-width: em(1200);
	    margin: 0 auto;
	    
	    @include bp(tab-xl) {
	    	padding: 0 em(20);
	    }
	}
	
	&__item,
	&__item--highlight {
		@include flex(0 1 em(350));
		margin: 0 em(10) em(50);
		background-color: rgba(white,0.95);
		border-radius: em(20);
		border: em(1) solid rgba($colorSecondary,0.1);
		color: $colorSecondary;
		position: relative;
		
		@include pushDown;
		
		@include bp(tab-xl) {
			@include flex(1 1 em(350));
		}
	}
	
	&__item-inner {
		display: block;
		padding: em(25) em(25) em(80);
		height: 100%;
		width: 100%;
		
		&:hover {
			color: $colorSecondary;
		}
	}
	
	&__item-inner--highlight {
		@extend .#{$module}__item-inner;
		@include display(flex);
		@include align-items(center);
		@include justify-content(center);
		
		padding: em(50) 10%;
		
		@include bp(tab-xl) {
			padding: em(50);
		}
		
		@include bp(mob-xl) {
			@include flex-direction(column);
			padding: em(25);
		}
	}
	
	&__icon {
		& > .svg-icon {
			margin: 0 auto em(40);	
		}
	}

	&__icon--highlight {
		
		@include bp(mob-xl) {
			margin: 0 auto em(40);
		}
		
		& > .svg-icon {
			height: em(350);
			width: em(350);
			
			@include bp(tab-l) {
				height: em(250);
				width: em(250);
			}
			
			@include bp(mob-xl) {
				height: em(200);
				width: em(200);
			}
		}
	}
	
	&__details {
		text-align: center;
		
		& .gamma {
			text-transform: uppercase;
		}
	}
	
	&__details--highlight {
		margin-left: em(50);
		@include flex(0 1 50%);
		
		@include bp(mob-xl) {
			text-align: center;
			margin: 0;
			@include flex(0 0 auto);
			max-width: 100%;
		}
	}
	
	&__copy {
		font-family: $ffSecondary;
	}
	
	&__more {
		position: absolute;
		overflow: hidden;
		padding: em(25) 0;
		height: em(80);
		bottom: 0;
		left: 50%;
		@include transform(translateX(-50%));
	}
	
	&__more--highlighted {
		overflow: hidden;
		padding: em(25) 0;
		height: em(80);
		@include transform(none);
	}
	
	&__more-copy {
		display: block;
		@extend .zeta;
		@include transform(translateY(em(100)));
		@include transition(transform 0.4s);
		
		.#{$module}__item:hover & {
			@include transform(translateY(0));
		}
		
		.#{$module}__more--highlighted & {
			@include transform(translateY(0));
		}
		
		@include bp(tab-l) {
			@include transform(none);
		}
	}
	
	&__highlight {
		margin-top: em(-50);
		padding: 0 em($contentPad) em(140);
		
		@include bp(desk-l) {
			padding: 0 em(20) em(140);
		}
	}
}