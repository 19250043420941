

$module: 'footer';

.#{$module} {
	margin: em(100) 0 0 0;
	padding: em(70);
	background-color: $color3;
	color: white;
	text-align: center;
	
	@include bp(mob-xl) {
		text-align: left;
	}
	
	@include bp(tab-s-a) {
		margin: em(50) 0 0 0;
		padding: em(50) em(20);
	}
	
	&__content {
		$fz: 16;
		font-size: em($fz);
		margin: em(50,$fz) 0;
		@include display(flex);
		@include justify-content(center);
		
		@include bp(mob-xl) {
			@include justify-content(flex-start);
			@include flex-direction(column);
		}
	}
	
	&__col {
		margin: 0 em(50) 0 0;
		text-align: left;
		
		@include bp(mob-xl) {
			margin: 0;
		}
	}
	
	&__col:last-child {
		margin: 0 0 0 em(50);
		
		@include bp(mob-xl) {
			margin: em(50) 0 0 0;
		}
	}
}