
$module: 'gooey';

.#{$module} {
	padding: em(10);
	display: inline-block;
	text-align: center;
	
	.services__tabs &,
	.work__filter-wrap & {
		@include bp(tab-l) {
			display: block;
		}
	}
	
	&__wrapper {
		visibility: hidden;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		text-align: center;
		
		&.selected {
			visibility: visible;
		}
	}
	
	&__rectangle {
		position: absolute;
		background-color: $color2;
		top: 50%;
		border-radius: em(20);
		z-index: 2;
		@include backface-visibility(hidden);
		@include transition-property(transform, width, height);
		@include transition-timing-function(ease-in);
		@include transform(translateY(-50%));
		
		.theme-aqua & {
			background-color: $color4;
		}
		
		.theme-blue & {
			background-color: $colorSecondary;
		}
		
		.theme-red & {
			background-color: $colorPrimary;
		}
		
		@include bp(tab-l-b) {
			top: auto; right: 0; left: 0;
			margin: 0 auto;
		}
	}

	&__filter {
		z-index: 2;
	}

	&__items {
		position: relative;
		border-radius: em(20);
		background-color: white;
		z-index: 1;
		display: inline-block;
		text-align: center;
		
		.theme-aqua & {
			border: 2px solid $color4;
		}
		
		.theme-red & {
			border: 2px solid $colorPrimary;
		}
		
		.theme-blue & {
			border: 2px solid $colorSecondary;
		}
		
		@include bp(tab-l-b) {
			display: none;
		}
	}

	&__item {
		position: relative;
		display: inline-block;
		margin: 0 em(15);
		color: $color2;
		z-index: 3;
		border-radius: em(20);
		cursor: pointer;
		@include transition-property(box-shadow, transform);
		@include transition-timing-function(ease-in);
		@include transition-duration(0.1s);
		@include transition-delay(0s,0.1s);
		
		&:nth-of-type(2), &:last-child {
			margin: 0;
		}
		
		.theme-aqua & {
			color: $color4;
		}

		.theme-blue & {
			color: $colorSecondary;
		}
		
		.theme-red & {
			color: $colorPrimary;
		}

		&.active {
			color: white;
		}
		
		@include bp(tab-l) {
			padding: em(20) em(15);
		}
		
	}
	
	&__inner {
		position: relative;
		$fontSize: 14;
		font-size: em($fontSize);
		padding: em(20,$fontSize) em(25,$fontSize);
		display: block;
		text-transform: uppercase;
		border: none;
		background-color: transparent;
		color: inherit;
		font-family: inherit;
		
		@include bp(tab-xl-b) {
			$fontSize: 12;
			font-size: em($fontSize);
		}
		
		&:focus {
			outline: none;
		}
	}
	
	&__select {
		display: none;
		text-align: left;
		margin: 0 auto;
		
		@include bp(tab-l-b) {
			display: block;
		}
	}
	
	&__select-wrap {
		position: relative;
		display: inline-block;
	}
	
	&__select-icon {
		position: absolute;
		top: 50%;
		right: em(20);
		@include transform(rotate(90deg)translateX(-50%));
		height: em(30);
		color: white;
	}
}