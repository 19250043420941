

$module: 'home';

.#{$module} {
	
	@include bp(desk-m) {
		padding: 0 em(20);
	}
	
	&__animation {
		position: absolute;
		top: em(-100);
		left: em(-120);
		width: 106.5%;
		padding: 0;
		
		@include bp(desk-m) {
			padding: 0 em(50) 0 0;
		}
		
		@include bp(mob-xl) {
			$extraWidth: em(150);
			width: calc(100% + #{$extraWidth});
			padding: 0 em(25) 0 0;
			top: em(-150);
			left: em(-150);
			height: em(350);
		}
		
		& > svg {
			overflow: visible;
			height: 100%;
			max-width: 100%;
		}
	}

	&__intro {
		margin: 0 0 em(50);
		padding: em(50) 40% em(50) em(50);
		color: $colorSecondary;
		
		@include bp(desk-l) {
			padding: em(100) 40% em(100) em(140);
		}
		
		@include bp(desk-m) {
			margin: 0 0 em(50);
			padding: em(110) 40% em(50) em(50);
		}
		
		@include bp(tab-s-b) {
			padding: em(100) 40% em(50) em(25);
		}
		
		@include bp(mob-xl) {
			padding: em(100) 0 em(25);
		}
	}
	
	&__header {
		$fz: 100;
		font-size: em(80);
		font-weight: 700; 
		margin-bottom: em(80,$fz);
		line-height: 1;
		
		@include bp(tab-m) {
			$fz: 70;
			font-size: em($fz);
		}
		
		@include bp(mob-xl) {
			$fz: 45;
			font-size: em($fz);
			max-width: 70%;
		}
	}
	
	&__intro-copy {
		max-width: em(600);
		margin-bottom: em(25);
		font-family: $ffSecondary;
	}
	
	&__links {
		@include display(flex);
		margin: 0 0 em(50);
		position: relative;
		
		@include bp(desk-m) {
			@include flex-wrap(wrap);
			margin: 0;
		}
	}
	
	&__link-work,
	&__link-services,
	&__link-pharma {
		border-radius: em(20);
		margin-right: em(50);
		@include flex(0 1 50%);
		padding: em(50) em(280) em(50) em(50);
		
		@include pushDown;
		
		@include bp(desk-xxl) {
			padding: em(50);
		}
		
		@include bp(desk-m) {
			margin: 0 0 em(50);
			@include flex(1 1 100%);
		}
		
		@include bp(mob-xl) {
			padding: em(25);
		}
		
		& .#{$module}__header {
			@include bp(desk-l-c) {
				$fz: 70;
				font-size: em($fz);
			}
			
			@include bp(desk-m) {
				$fz: 100;
				font-size: em($fz);
			}
			
			@include bp(tab-m) {
				$fz: 70;
				font-size: em($fz);
			}
			
			@include bp(mob-xl) {
				$fz: 45;
				font-size: em($fz);
			}
			
		}
	}
	
	&__link-work {
		color: white;
		background-color: $color2;
		
		&:hover {
			color: white;
		}
	}
	
	&__link-services {
		color: $colorSecondary;
		border: em(1) solid rgba($colorSecondary,0.1);
		background-color: white;
		
		&:hover {
			color: $colorSecondary;
		}
	}
	
	&__link-pharma {
		color: white;
		background-color: $color4;
		
		&:hover {
			color: white;
		}
	}
	
	&__link-work-inner {
		height: 100%;
		@include display(flex);
		@include flex-direction(column);
		@include justify-content(space-between);
	}
	
	&__more {
		@extend .delta;
		text-transform: uppercase;
	}
	
	& .services__item {
		margin: 0 em(50) 0 0;
		@include flex(1 1 em(300));
		
		&:last-of-type {
			margin: 0;
		}
		
		@include bp(desk-m) {
			margin: 0 em(50) em(50) 0;
			
			&:last-of-type {
				margin: 0 0 em(50);
			}
		}
		
		@include bp(tab-s) {
			@include flex(1 1 100%);
			margin: 0 0 em(50) 0;
		}
	}
	
	& .services__item-inner {
		padding: em(50) em(25) em(75);
		
		@include bp(desk-m) {
			padding: em(25) em(25) em(80);
		}
	}
	
	& .parallax-card__item,
	& .parallax-card__item--big {
		width: auto;
		@include flex(1 1 30%);
		
		@include bp(desk-m) {
			margin: 0 0 em(50);
		}
		
		@include bp(tab-s) {
			@include flex(1 1 100%);
		}
	}
	
	& .parallax-card__wrapper {
		@include bp(tab-s) {
			height: em(250);
		}
	}
	
	& .parallax-scroll__services {
		display: none;
	}
	
	&__contacts {
		color: white;
		background-color: $colorSecondary;
		border-radius: em(20);
		box-shadow: $insetShadow;
		@include flex(0 1 50%);
		@include display(flex);
		
		@include bp(desk-m) {
			@include flex(1 1 100%);
		}
		
		@include bp(tab-s-b) {
			@include flex-direction(column);
		}
		
		&:hover {
			color: white;
		}
	}
	
	&__contacts-map {
		border-radius: em(11);
		margin: em(10);
		overflow: hidden;
		@include flex(0 1 50%);
	}
	
	&__contacts-details {
		@include display(flex);
		@include justify-content(space-between);
		@include align-items(flex-start);
		@include flex-direction(column);
		@include flex(0 1 50%);
		padding: em(50);
		
		@include bp(tab-s-b) {
			@include flex(1 0 auto);
		}
		
		@include bp(mob-xl) {
			padding: em(25);
		}
		
		& .social-icons {
			padding-top: em(20);
		}
	}
	
	&__contacts-content {
		max-width: 100%;
		font-family: $ffSecondary;
	}
	
	&__contacts-address {
		$fz: 16;
		font-size: em($fz);
		
		& .telephone {
			margin-bottom: em(20);
			display: inline-block;
		}
	}
	
	&__clients {
		margin: em(20) 0 em(50);
		width: 100%;
		position: relative;
		z-index: -1;
		
		@include bp(mob-xl) {
			$addPad: em(40);
			margin: 0 em(-20) em(50);
			width: calc(100% + #{$addPad});
		}
		
		&:after {
			position: absolute;
			content: '';
			display: block;	
			height: 100%;
			width: em(50);
			top: 0;
			right: 0;
			background-image: linear-gradient(to right, rgba(white,0), rgba(white,1));
		}
	}
	
	&__client {
		display: inline-block;
		margin: 0 em(25);
		height: em(100);
		@include display(flex);
		
		@include bp(mob-xl) {
			height: em(75);
			margin: 0 em(15);
		}
	}
	
	&__client-logo {
		max-width: em(100);
		max-height: 100%;
		@include align-self(center);
		
		@include bp(mob-xl) {
			max-width: em(75);
		}
	}

}