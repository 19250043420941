

$module: 'nav-fixed';

.#{$module} {
	$fontSize: 22;
	font-size: em($fontSize);
	font-weight: $fwBold;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 30;
	background-color: white;
	@include transition-property(transform);
	@include transition-duration(0.15s);
	@include transition-timing-function(linear);
	box-shadow: 0 -2px 2px rgba(black,0.1);
	
	&.hidden {
		@include transform(translateY(100%));
	}
    
    &__items {
		@include display (flex);
		@include justify-content (space-between);
		color: black;
		font-weight: 700;
		text-transform: uppercase;
		height: 7rem;
	}

	&__item {
		position: relative;
		overflow: hidden;
		width: 25%;
		margin: 0 2px;
		@include display(flex);
		@include flex-flow(column wrap);
		@include align-items(center);
		@include justify-content(center);
		
		@include bp(tab-m) {
			width: 33%;
		}
		
		&.no-mob {
			@include bp(tab-m) {
				display: none;
			}
		}

		&* {
			backface-visibility: hidden;
		}
	}

	&__pancake {
		@extend %pancake__lines;
		background-color: $colorPrimary !important;
		
		.active & {
			background-color: white !important;
			
			&:before, &:after {
				background-color: white;
				@include transform(none);
			}
			
			&:before {			
				top: -1rem;
			}
			
			&:after {
				top: 1rem;
			}
		}
	}
}