
$module: 			'timeline';

.#{$module} {
	
	&__year {
		@include display(flex);
		position: relative;
		padding: em(70) em(150) 0 em(150);
		
		@include bp(desk-l) {
			padding: em(70) em(30) 0 em(30);
		}
		
		@include bp(tab-m) {
			@include flex-direction(column-reverse);
		}
		
		&:nth-of-type(even) {
			@include flex-direction(row-reverse);
			
			@include bp(tab-m) {
				@include flex-direction(column-reverse);
			}
			
			& .#{$module}__items {
				text-align: right;
				padding: 0 em(100) 0 0;
				
				@include bp(tab-xl) {
					padding: 0 em(50) 0 0;
				}
				
				@include bp(tab-m) {
					text-align: left;
					padding: 0 em(50);
				}
				
				@include bp(mob-xl) {
					padding: 0;
				}
			}
			
			& .#{$module}__extra {
				text-align: left;
				@include align-items(flex-start);
				
				& p {
					text-align: left;
				}
			}
			
			& .#{$module}__logo {
				margin: em(30) em(30) 0 0;
			}
			
			& .#{$module}__client {
				margin: em(50) 0 0 em(50);
				
				@include bp(tab-l) {
					margin: em(30) 0 0 em(30);
				}
				
				@include bp(tab-m) {
					margin: em(30) em(30) 0 0;
				}
			}
			
			& .#{$module}__item:before {
				left: auto;
				right: em(-100);
				
				@include bp(tab-xl) {
					right: em(-50);
				}
				
				@include bp(tab-m) {
					right: auto;
					left: em(-80);
				}
				
				@include bp(mob-xl) {
					left: em(-35);
				}
			}
		}
		
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: em(10);
			height: 100%;
			background-color: $color1;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			
			@include bp(tab-m) {
				left: 0;
			}
		}
		
		&:last-of-type:before {
			height: em(110);
		}
		
		&:after {
			content: '';
			display: block;
			width: em(40);
			height: em(40);
			background: white;
			position: absolute;
			left: 50%;
			top: em(100);
			transform: translateX(-50%);
			border-radius: 50%;
			border: 10px solid $color1;
			
			@include bp(tab-l) {
				top: em(75);
			}
			
			@include bp(tab-m) {
				left: 0;
			}
		}
	}
	
	&__extra {
		@include flex(0 1 50%);
		@include display(flex);
		@include align-items(flex-end);
		@include flex-direction(column);
		padding: em(50) em(100);
		max-width: 50%;
		
		@include bp(tab-xl) {
			padding: em(50) em(50);
		}
		
		@include bp(tab-m) {
			text-align: left;
			@include align-items(flex-start);
			@include flex(1 1 auto);
			padding: em(50) em(50);
			max-width: 100%;
		}
		
		@include bp(mob-xl) {
			padding: em(50) 0;					
		}
		
		p {
			$fz: 16;
			font-size: em($fz);
			max-width: 100%;
			text-align: right;
			color: $color4dark;
			
			@include bp(tab-m) {
				text-align: left;
			}
		}
	}
	
	&__logo {
		max-width: em(45);
		max-height: em(45);
		margin: em(30) 0 0 em(30);
		vertical-align: middle;
		
		@include bp(tab-m) {
			margin: em(30) em(30) 0 0;
		}
	}
	
	&__date {
		@extend .alpha;
		color: $color1;
		text-shadow: em(4,100) em(4,100) $color1light;
	}
	
	&__items {
		@include flex(0 1 50%);
		padding: 0 0 0 em(100);
		max-width: 50%;
		
		@include bp(tab-xl) {
			padding: 0 0 0 em(50);
		}
		
		@include bp(tab-m) {
			@include flex(1 1 auto);
			max-width: 100%;
			padding: 0 em(50);
		}
		
		@include bp(mob-xl) {
			padding: 0;
		}
	}
	
	&__item {
		color: $color3;
		font-family: $ffSecondary;
		position: relative;
		
		&:not(:first-of-type) {
			margin-top: em(50);
			
			&:before {
				content: '';
				display: block;
				position: absolute;
				background-color: $color1;
				width: em(20);
				height: em(10);
				left: em(-100);
				top: em(5);
				
				@include bp(tab-xl) {
					left: em(-50);
				}
				
				@include bp(tab-m) {
					left: em(-80);
				}
				
				@include bp(mob-xl) {
					left: em(-35);
				}
			}
		}
	}
	
	&__client {
		max-width: em(120);
		max-height: em(120);
		margin: em(50) em(50) 0 0;
		vertical-align: middle;
		
		@include bp(tab-xl) {
			max-width: em(80);
			max-height: em(80);
			margin: em(30) em(30) 0 0;
		}
	}
	
	&__button {
		@include display(flex);
		@include align-items(center);
		@include flex(1 1 auto);
		position: relative;
		border-radius: em(20);
		border: em(1) solid rgba($colorSecondary,0.1);
		height: em(180);
		margin-top: em(50);
		text-align: left;
		background: white;
		@include pushDown;
		
		@include bp(tab-xl) {
			text-align: center;
			height: auto;
			@include flex-direction(column);
		}
		
		@include bp(tab-m) {
			text-align: left;
			height: em(180);
			@include flex-direction(row);
		}
		
		@include bp(mob-l) {
			text-align: center;
			height: auto;
			@include flex-direction(column);
		}
		
		&:hover {
			color: $color3;
		}
		
		&:last-of-type {
			margin-top: em(20);
		}
		
	}
	
	&__button-title {
		position: relative;
		text-transform: uppercase;
		font-family: $ffBase;
		padding: 0 em(50) 0 em(30);
		@include flex(1 1 auto);
		width: 100%;
		
		@include bp(tab-xl) {
			padding: em(20) em(50);
		}
		
		@include bp(tab-m) {
			padding: 0 em(50) 0 em(30);
		}
		
		@include bp(mob-l) {
			padding: em(20) em(35);
		}
		
		&:before, &:after {
			content:'';
			display: block;
			position: absolute;
			top: 50%;
			right: em(20);
			height: em(5);
			width: em(20);
			border-radius: em(4);
			background-color: $color3;
			
			@include bp(mob-xl) {
				right: em(10);
			}
		}
		
		&:before {
			transform: rotate(45deg) translate(em(-8),em(-8));
		}
		
		&:after {
			transform: rotate(-45deg);
		}
	}
	
	&__service-icon {
		@include flex(0 0 em(180));
		padding: em(20);
	}
	
	&__case-study-image {
		@include flex(0 0 em(180));
		height: 100%;
		width: 100%;
		border-radius: em(20) 0 0 em(20);
		background-size: cover;
		background-position: center;
		
		@include bp(tab-xl) {
			border-radius: em(20) em(20) 0 0;
		}
		
		@include bp(tab-m) {
			border-radius: em(20) 0 0 em(20);
		}
		
		@include bp(mob-l) {
			border-radius: em(20) em(20) 0 0;
		}
	}
	
}