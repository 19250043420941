

/* @group Base */
.chosen-container {
  position: relative;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  height: auto;
  text-transform: uppercase;
  font-weight: $fwBold;
  max-width: em(300);
  
  &:after {
  	content: '';
  	display: block;
  	
  }
  
  * {
    box-sizing: border-box;
  }
  .chosen-drop {
    position: absolute;
    top: 100%;
    z-index: 1010;
    width: 100%;
    border-top: 0;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(black, 0.35);
    clip: rect(0,0,0,0);
  }
  &.chosen-with-drop .chosen-drop {
    clip: auto;
  }
  a{
    cursor: pointer;
  }

  .search-choice, .chosen-single{
    .group-name{
      margin-right: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: normal;
      color: #999999;
      &:after {
        content: ":";
        padding-left: 2px;
        vertical-align: top;
      }
    }
  }
}
/* @end */

/* @group Single Chosen */
.chosen-container-single{
  .chosen-single {
  	$fontSize: 16;
  	font-size: em($fontSize);
    position: relative;
    display: block;
    overflow: hidden;
    padding: em(20,$fontSize) 0 em(20,$fontSize) em(27,$fontSize);
    height: auto;
    border: 2px solid white;
    border-radius: em(15,$fontSize);
    background-color: $color4;
    background-clip: padding-box;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    line-height: 30px;
    
    .theme-yellow & {
    	background-color: transparent;
    }
  }
  .chosen-default {
    color: #999;
  }
  .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chosen-single-with-deselect span {
    margin-right: 38px;
  }
  .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    font-size: 1px;
    &:hover {
      background-position: -42px -10px;
    }
  }
  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
  }
  .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
    b {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;

    input[type="text"] {
      margin: 1px 0;
      padding: 4px 20px 4px 5px;
      width: 100%;
      height: auto;
      outline: 0;
      border: 1px solid #aaa;
      font-size: 1em;
      font-family: sans-serif;
      line-height: normal;
      border-radius: 0;
    }
  }
  .chosen-drop {
    border-radius: 15px;
  }
  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    clip: rect(0,0,0,0);
  }
}
/* @end */

/* @group Results */
.chosen-container .chosen-results {
  color: $color3;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
  li {
    display: none;
    margin: 0;
    padding: em(24) em(27);
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
    &.active-result {
      display: list-item;
      cursor: pointer;
    }
    &.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default;
    }
    &.highlighted {
      $fontSize: 24;
      font-size: em($fontSize);
      line-height: 0.9;
      @include transition(font-size 0.1s $inout);
    }
    &.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4;
    }
    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default;
    }
    &.group-option {
      padding-left: 15px;
    }
    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}
/* @end */

/* @group Multi Chosen */
.chosen-container-multi{
  .chosen-choices {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0 5px;
    width: 100%;
    height: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    background-image: linear-gradient(#eee 1%, #fff 15%);
    cursor: text;
  }
  .chosen-choices li {
    float: left;
    list-style: none;
    &.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      input[type="text"] {
        margin: 1px 0;
        padding: 0;
        height: 25px;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        box-shadow: none;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border-radius: 0;
        width: 25px;
      }
    }
    &.search-choice {
      position: relative;
      margin: 3px 5px 3px 0;
      padding: 3px 20px 3px 5px;
      border: 1px solid #aaa;
      max-width: 100%;
      border-radius: 3px;
      background-color: #eeeeee;
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
      background-size: 100% 19px;
      background-repeat: repeat-x;
      background-clip: padding-box;
      box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(#000,.05);
      color: #333;
      line-height: 13px;
      cursor: default;
      span {
        word-wrap: break-word;
      }
      .search-choice-close {
        position: absolute;
        top: 4px;
        right: 3px;
        display: block;
        width: 12px;
        height: 12px;
        font-size: 1px;
        &:hover {
          background-position: -42px -10px;
        }
      }
    }
    &.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
      color: #666;
    }
    &.search-choice-focus {
      background: #d4d4d4;
      .search-choice-close {
        background-position: -42px -10px;
      }
    }
  }
  .chosen-results {
    margin: 0;
    padding: 0;
  }
  .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
  }
}
/* @end */

/* @group Active  */
.chosen-container-active{
  .chosen-single {
    border: 2px solid white;
  }
  &.chosen-with-drop{
    .chosen-single div {
      border-left: none;
      background: transparent;
      b {
        background-position: -18px 2px;
      }
    }
  }
  .chosen-choices {
    border: 1px solid #5897fb;
    box-shadow: 0 0 5px rgba(#000,.3);
    li.search-field input[type="text"] {
      color: #222 !important;
    }
  }
}
/* @end */

/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
  .chosen-single {
    cursor: default;
  }
  .chosen-choices .search-choice .search-choice-close {
    cursor: default;
  }
}
/* @end */

/* @group Right to Left */
.chosen-rtl {
  text-align: right;
  .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0;
  }
  .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl;
  }
  .chosen-single-with-deselect span {
    margin-left: 38px;
  }
  .chosen-single div {
    right: auto;
    left: 3px;
  }
  .chosen-single abbr {
    right: auto;
    left: 26px;
  }
  .chosen-choices li {
    float: right;
    &.search-field input[type="text"] {
      direction: rtl;
    }
    &.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px;
      .search-choice-close {
        right: auto;
        left: 4px;
      }
    }
  }
  &.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 0 0 em(27);
  }
  .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0;
  }
  &.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
  }
  .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    direction: rtl;
  }
  &.chosen-container-single{
    .chosen-single div b {
      background-position: 6px 2px;
    }
    &.chosen-with-drop{
      .chosen-single div b {
        background-position: -12px 2px;
      }
    }
  }
}

/* @end */

/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
}
/* @end */