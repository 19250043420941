

$module: 'project';


.#{$module} {
	line-height: 1.6;
	color: $colorSecondary;
	
	&__screens {
		@include display(flex);
		@include justify-content(center);
		max-width: 100%;
		background-color: $color5;
		height: em(500);
		padding: 0 em(25);
		
		@include bp(desk-l-c) {
			height: em(450);
		}
		
		@include bp(tab-m) {
			height: em(400);
		}
		
		@include bp(mob-xl) {
			height: em(350);
		}
		
		@include bp(mob-l-a) {
			height: em(300);
		}
		
		@include bp(mob-m) {
			height: em(250);
		}
		
	}
	
	&__screen--desktop,
	&__screen--mobile {
		height: 100%;
		margin-top: em(-50);
		padding: 0 em(25);
	}
	
	&__screen--desktop {
		@include flex(0 0 60%);
		
		@include bp(desk-m) {
			@include flex(0 0 70%);
		}
		
		@include bp(tab-m) {
			@include flex(0 0 100%);
		}
		
		@include bp(tab-s-b) {
			margin-top: em(-25);
		}
		
		@include bp(mob-xl) {
			padding: 0;
		}
	}
	
	&__screen--mobile {
		@include flex(0 0 20%);
		
		@include bp(desk-m) {
			@include flex(0 0 30%);
		}
		
		@include bp(tab-m) {
			display: none;
		}
	}
	
	&__screen--mobile:last-of-type {
		
		@include bp(desk-m) {
			display: none;
		}
	}
	
	&__screen-inner {
		display: block;
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-size: 100% auto;
		border-radius: em(20);
		
		@include bp(tab-m) {
			border-radius: em(10);
		}
	}
}