

$module: 'modal';

.#{$module} {
	$fontSize: 16;
	font-size: em($fontSize);
	color: white;
	position: relative;
	border: 2px solid white;
	border-radius: em(15,$fontSize);
	margin: 0 auto em(40,$fontSize);
	padding: em(20,$fontSize) em(45,$fontSize) em(20,$fontSize) em(25,$fontSize);
	max-width: em(282,$fontSize);
	cursor: pointer;
	@include transition-property(background-color, color);
	@include transition-duration(0.2s);
	@include transition-timing-function(ease-in);
	
	&.modal-active {
		background-color: $color2;
		border-color: $color2;
		color: $color3;
	}	
	
	&:hover {
		background-color: $color1;
	//	border-color: $color1;
	}
	
	&:hover.modal-active {
		background-color: $color2;
	}
	
	&--alt {
		@extend .#{$module};
		color: $color3;
		border-color: $color3;
	}
	
	&--alt:hover {
		color: white;
	}
	
	.editorial & {
		margin: 0 0 em(50) auto;
		
		@include bp(tab-s-a) { margin: 0 auto em(50); }
	}
	
	&__title {
		font-weight: $fwBold;
		margin: 0 0 em(29);
	}
	
	&__content {
		word-wrap: break-word;
		line-height: 1.2;
    	display: block;
	}
	
	&__arrow {
		$fontSize: 16;
		position: absolute;
		top: 50%;
		right: em(25,$fontSize);
		width: em(20,$fontSize);
		height: em(30,$fontSize);
		@include transform(translateY(-50%));
		
		> svg {
			@include transition-property(transform);
			@include transition-duration(0.2s);
			@include transition-timing-function(ease-in);
			
			.modal-active & {
				@include transform(rotate(180deg));
			}
		}
		
	}
}