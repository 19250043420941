

$module: 'social-icons';

.#{$module} {
	margin: 0 auto;
	color: inherit;
	
	&__items {
		@include display(flex);
		@include justify-content(center);
		@include align-items(center);
		
		@include bp(mob-xl) { 
			@include justify-content(flex-start);
		}
	}
	
	&__items--right {
		@extend .#{$module}__items;
		@include justify-content(flex-end);
	}
	
	&__items--left {
		@extend .#{$module}__items;
		@include justify-content(flex-start);
	}
	
	&__item {
		padding: 0 em(5);
		
		@include bp(mob-xl) {
			padding: 0 em(10) 0 0;
		}
	}
	
	&__anchor {
		background-color: rgba(white,0);
		@include transition(all 0.4s);
		display: block;
		height: em(40);
		width: em(40);
		border-radius: em(25);
		@include display(flex);
		@include justify-content(center);
		box-shadow: 0 0 0 0 transparent;
		
		&:hover {
			color: $colorSecondary;
			background-color: rgba(white,1);
			box-shadow: 0 em(10) em(20) em(-4) rgba(black,0.4);;
		}
	}
}