

$module: 				'top-nav';


//this is the nav background
.header:before {
	content: '';
	background-color: rgba(white,0.97);
	display: block;
	position: fixed;
	bottom: 50%;
	left: 50%;
	height: em(2000);
	width: em(2000);
	@include transform(scale(0));
	@include transition(transform 0.8s,visibility 0.1s ease-in-out 0.4s);
	z-index: 40;
	border-radius: 100%;
}

.header.active:before {
	@include transform(scale(3));
	@include transition(transform 0.8s, visibility 0.1s);
}


.#{$module} {
	position: fixed;
	top: 0;
	left: 50%;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	visibility: hidden;
	padding: em(120) em(200) em(35);
	text-align: right;
	opacity: 0;
	@include transition(opacity 0.6s);
	@include display(flex);
	@include flex-direction(column);
	@include justify-content(space-between);
	overflow-y: scroll;
	overflow-x: hidden;
	max-width: em(1920);
	
	@include transform(translateX(150%));
	@include transition(transform 0.4s);
	
	.header.active & {
		visibility: visible;
		opacity: 1;
		@include transform(translateX(-50%));
	}
	
	@include bp(extra-large) {
		overflow: visible;
	}
	
	@include bp(tab-xl) {
		padding: em(80) em(40) em(35);
	}
	
	@include bp(mob-m-a) {
		padding: em(80) em(20) em(20);
	}
	
	&__item--blue,
	&__item--red,
	&__item--aqua,
	&__item--yellow {
		display: block;
		$fz: 85;
		color: $colorPrimary;
		font-weight: 700;
		font-size: em($fz);
		text-transform: uppercase;
		
		@include bp(mob-xl) {
			$fz: 50;
			font-size: em($fz);
		}
		
		&:before {
			content: attr(title);
			opacity: 0;
			position: absolute;
			height: 100vh;
			width: calc(100vw + 15%);
			bottom: 0;
			right: 0;
			font-size: 200vh;
			line-height: 0.3;
			overflow: hidden;
			z-index: -1;
			white-space: nowrap;
			@include transition(opacity 0.4s);
			pointer-events: none;
			
			@include bp(extra-large) {
				overflow: visible;
			}
		}
		
		&:hover:before {
			opacity: 0.8;
		}
	}
	
	&__item--blue:hover,
	&__item--blue.current {
		color: $colorSecondary;
	}
	
	&__item--red:hover,
	&__item--red.current {
		color: $colorPrimary;
	}
	
	&__item--aqua:hover,
	&__item--aqua.current {
		color: $color4;
	}
	
	&__item--yellow:hover,
	&__item--yellow.current {
		color: $color2;
	}
	
	&__item--blue:before {
		color: $color3light;
	}
	
	&__item--red:before {
		color: $color1light;
	}
	
	&__item--aqua:before {
		color: $color4light;
	}
	
	&__item--yellow:before {
		color: $color2light;
	}
	
	&__social {
		color: $colorSecondary;
	}
	
	&__footer {
		@include display(flex);
		@include flex(0 0 auto);
		@include justify-content(flex-end);
		color: $colorSecondary;
		margin-top: em(20,16);
		font-size: em(16);
		
		@include bp(mob-l) {
			@include flex-direction(column);
		}
	}
	
	&__footer-items {
		margin-right: em(20);
		
		@include bp(mob-l) {
			margin: 0 0 em(20);
		}
		
		& > li {
			display: inline-block;
		}
	}
	
	&__footer-item {
		margin: 0 em(20);
		
		@include bp(mob-l) {
			margin: 0 0 0 em(20);
		}
	}
}