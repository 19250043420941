

//
// Main Layout
// --------------------------------------------------

*{
	box-sizing: inherit; // IE8 Compatabile (doesnt work with max-width,min width however careful)
	&:after,
	&:before{
		box-sizing: inherit;
	}
}

html{
	font-size: 62.5%;
	box-sizing: border-box;
	
	&.noTouch * {
		cursor: none !important;
	}
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: $ffBase;
	@include font-size($fzBase);
	line-height: ($lhBase/$fzBase);
	color: $colorText;
	background-color: $colorBody;
	-webkit-font-smoothing: antialiased;
	
	&.nav-active {
	 	overflow: hidden;
	}
}

main {
	position: relative;
	opacity: 1;
	@include transition-property(transform, opacity);
	@include transition-duration(1s);
	@include transition-timing-function(ease-in);

	&.transitioning {
		pointer-events: none;
		opacity: 0;
		@include transform(translateY(-10%));
	}
}

// Links
// -------------------------

a {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: $colorHover;
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}


// Select
// ----------------------
select {
  	$fontSize: 16;
  	font-size: em($fontSize);
    position: relative;
    display: block;
    overflow: hidden;
    padding: em(20,$fontSize) 0 em(20,$fontSize) em(27,$fontSize);
    height: auto;
    border: 2px solid white;
    border-radius: em(15,$fontSize);
    background-color: $color4;
    background-clip: padding-box;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    line-height: 30px;
    -webkit-appearance: inherit;
    width: em(310);
    
    .theme-yellow & {
    	background-color: transparent;
    }
    
    .theme-white & {
    	border-color: $color3;
    	color: $color3;
    	background-color: transparent;
    }
}