
$module: 	'l';

.#{$module} {
	&-wrapper {
		width: 100%; 
		max-width: em(1920);
		padding: 0 em(70) em(100); // bottom to offset fixed nav
		margin: 0 auto;
		position: relative;
		opacity: 1;
		@include animation-timing-function(ease-in);
	  	@include animation-iteration-count(1);
	  	@include animation-duration(1.5s);
	  	z-index: 1;
	
		&.transitioning {
			@include animation-name(slideUpFade);
			pointer-events: none;
		}
		
		@include bp(tab-xl) {
			padding: 0 0 em(100);
		}
	}
	
	&-content-wrapper {
		padding: 0 em($contentPad);
		
		@include bp(tab-xl) {
			padding: 0 em(35);
		}
	}
}