
//
// Helper classes
// --------------------------------------------------

.clearfix{
	@include clearfix;
}


// Toggling content
.hide {
	display: none !important;
}
.show {
	display: block !important;
}

// Image replcaement
.ir { 
	display: block;
	border: 0; 
	text-indent: -999em; 
	overflow: hidden; 
	background-color: transparent; 
	background-repeat: no-repeat; 
	text-align: left; 
	direction: ltr;
}