

$module: 		'carousel';


.#{$module} {
	padding: em(50) 0;
	background-color: $color5;
	margin-bottom: em(140);
	
	&__image,
	&__image--desktop,
	&__image--mobile {
		position: relative;
		padding: em(50) 0;

		@include screenshotsGallery();
	}
	
	&__image,
	&__image--desktop {
		width: 60%;
		
		@include bp(tab-s) {
			width: 75%;
		}
	}
	
	&__image--mobile {
		width: 20%;
		
		@include bp(tab-s) {
			width: 35%;
		}
	}
	
	&__bg-desktop,
	&__bg-mobile {
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		
		.#{$module}__image--desktop &,
		.#{$module}__image--mobile & {
			border-radius: em(20);
			background-position: top;
			
			@include bp(tab-m) {
				border-radius: em(10);
			}
		}
	}

	
	&__bg-desktop {
		display: block;
		
		@include bp(tab-m) {
			display: none;
		}
	}
	
	&__bg-mobile {
		display: none;
		
		@include bp(tab-m) {
			display: block;
		}
	}

	
}