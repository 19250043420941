

$module: 		'banner';

.#{$module} {
	$fontSize: 30;
	font-size: em($fontSize);
	@include fp(font-size,22,$fontSize,600);
	background-color: $colorPrimary;
	text-align: center;
	color: white;
	padding: 0 0 em(50) 0;
}