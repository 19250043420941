
$padVert: 115;
$padHoriz: 25;
$imgWidth: 757;
$parallaxInnerLeftAndRightPad: percentage(108/1780);

$module: 'parallax-scroll';

.#{$module} {
	margin: 0 0 em(70);
	color: white;
	text-transform: uppercase;
	overflow: hidden;


	&__item, &__item--alt {
		position: relative;
		margin: 0 0 em(205);
		
		@include bp(tab-xl-b) {
			margin: 0 0 em(100);
		}
		
		@include bp(mob-xl) {
			margin-bottom: 0;
		}
	}
	
	&__panel {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		@include transform (translateX(-101%));
		@include transition-duration (0.4s);
		@include transition-timing-function (ease-in);
		
		.#{$module}__item--alt & {
			transform: translateX(101%);
		}
		
		.active & {
			@include transform(translateX(0));
		}
	}
	
	&__inner {
		padding: 0 $parallaxInnerLeftAndRightPad;
	}

	&__content {
		width: em(727);
		max-width: percentage(726/1560);
		padding: em(115) 0;
		margin-left: auto;
		position: relative;
		opacity: 0;
		@include transform (translateX(100%));
		@include transition-property (transform);
		@include transition-duration (0.4s);
		@include transition-timing-function (ease-in);

		.#{$module}__item--alt & {
			margin-left: 0;
		}

		.active & {
			opacity: 1;
			@include transform (translateX(0));
		}
		
		@include bp(tab-l) {
			z-index: 2;
		}
		
		@include bp(tab-s) {
			width: em(600);
			max-width: 100%;
			margin: 0 auto !important;
			padding: 0 0 em(50);
		}
	}

	&__heading {		
		@extend .alpha;
		$fontSize: 90;
		line-height: (80/$fontSize);
		@include fp(font-size, 24, $fontSize, 320, 1400);
		margin-left: -0.5rem;
		
		@include bp(tab-s) {			
			margin: 0 0 4.5rem -0.5rem;
		}
	}
	
	&__desc {
		$fontSize: 22;
		font-size: em($fontSize);
		font-family: $ffSecondary;
		text-transform: none;
		margin: 0 0 em(45,$fontSize);
		width: em(305,$fontSize);
		max-width: 100%;
	}

	&__img {
		width: em(783);
		padding-bottom: percentage(585/1780);
		max-width: percentage(780/1780);
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 15%;
		left: $parallaxInnerLeftAndRightPad;
		z-index: 10;
		box-shadow: $boxShadow;
		@include transition-property(transform, opacity);
		@include transition-duration(0.1s, 0.2s);
		@include transition-timing-function(ease-in);
		@include transition-delay(0, 0.4s);
		opacity: 0;

		.#{$module}__item--alt & {
			left: auto;
			right: $parallaxInnerLeftAndRightPad;
		}
		
		.active & {
			opacity: 1;
		}
		
		@include bp(tab-s) {
			position: static;
			margin: 0 auto em(30);
			padding-bottom: 50%;
			max-width: 100%;
		}
	}

	&__link {
		$fontSize: 24;
		font-size: em($fontSize);
		@include display(flex);
		@include align-items(center);
		position: absolute;
		bottom: em(115,$fontSize);
	    right: 0;
		z-index: 2;
		cursor: pointer;
		font-weight: $fwBlack;
		
		> .svg-icon {
			margin: 0 0 0 1.8rem;
		}

		.active & {
			opacity: 1;
			@include transition(opacity 0.2s ease-in 0.4s);
		}
		
		@include bp(tab-l) {
			bottom: 2rem;
			top: auto;
			@include transform(none);
		}
		
		#section156 &:hover {
			color: $color1;
		}
	}
	
	&__btn-wrap {
		padding: em(70) 0 em(20);
		text-align: center;
	}
	
	&__services {
	    $fontSize: 16;
	    font-size: em($fontSize);
    	font-weight: $fwBold;
    	text-transform: uppercase;
    	
    	> li {
    		margin: 0 0 em(4,$fontSize) 0;
    	}
	}
}