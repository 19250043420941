
//
// Typography
// --------------------------------------------------


// Body text
// -------------------------


.editorFullBreak {
	clear: both;
}

.rteCopy {
	color: $color3;
}

// Emphasis & misc
// -------------------------

small {
	font-size: 85%;
}

.smaller-text {
	font-size: 70%;
}

strong {
	font-weight: $fwBlack;
}

em {
	font-style: italic;
}

cite {
	font-style: normal;
}

.editorUnderline {
	text-decoration: underline;
}

.editorStrikethrough {
	text-decoration: line-through;
}

.rtebr {
	display: block;
	height: em(10);
}

.serif {
	font-family: $ffSecondary;
}


// Headings
// -------------------------

h1,h2,h3,h4,h5 {
	line-height: normal;
	font-weight: normal;
}


@for $i from 1 through length($headings) {
	.rteCopy h#{$i},#{nth($headings,$i)} {
		@include font-size(nth($headingSizes,$i));
		font-family: #{nth($headingFamilies,$i)};
		color: #{nth($headingColors,$i)};
		line-height: (nth($headingLineHeights,$i)/nth($headingSizes,$i));
		margin-bottom: em(nth($headingMargins,$i),nth($headingSizes,$i));
	}
}

.alpha, .beta, .gamma, .delta, .zeta {
	font-weight: $fwBold;
}

.alpha, .rteCopy h1 {
	text-transform: uppercase;
	
	@include bp(tab-l) {
		@include font-size(50);
	}
}

.alpha--alt {
	@extend .alpha;
	color: $color3;
}

.alpha--low {
	@extend .alpha;
	text-transform: none;
}

.beta, .rteCopy h2 {
	text-transform: uppercase;
	
	@include bp(tab-l) {
		@include font-size(30);
	}
}

.hugetext {
	@extend .alpha;
	text-transform: none;
	
	.twoCol & {
		@include fp(font-size, 30, 100, 600, 1700);
	}
}


// Bullets
// -------------------------

.editorList,
.editorRom,
.editorBullets{
  	margin: 0 0 em($lhBase) em(20);
  	list-style-type: disc;
}



ol.editorList, .rteCopy ol {
	list-style-type: decimal;
}

ol.editorRom {
	list-style-type: lower-roman;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul.editorList {
	list-style-type: disc;
	
	> li {
		position: relative;
		font-family: $ffBase;
		
		.lt-ie9 & {
			background-image: none;
			padding-left: 0;
		}
	}
	
	li {
		margin-bottom: em(15);
	}
}

.rteCopy ol, ol.editorList {
	li {
		position: relative;
		margin-left: em(18);
		padding-left: em(6);
	}
}

// sub and sup
sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}


//Text alignment
// -------------------------

.centerJustify{
	width: 100%; 
	text-align: center;
}

.leftJustify{
	width: 100%; 
	text-align: left;
}

.rightJustify{
	width: 100%;
	text-align: right;
}

.justified{
	width: 100%;
	text-align: justify;
	-webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

// Quotes
// -------------------------

.inlineQuote{
	font-style: italic;
}
.inlineCite{
	font-style: italic;
	font-weight: bold;
}
.editorBlockQuote{
	padding: 0 0 0 em(15);
  	margin: 0 0 em($lhBase);
  	
	p {
		margin-bottom: 0;
	}
	
	small {
		display: block;
		&:before {
		  content: '\2014 \00A0';
		}
	}
}