
%button {
	background-color: transparent;
	display: inline-block;
	margin-bottom: 0;
	font-family: $ffBase;
	text-align: center;
	vertical-align: middle;
  	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	@include user-select(none);
	text-decoration: none !important;
	
	&:focus {
		outline: 1px dotted $colorActive;
	}
}

%innerwrapper {
	padding: 0 em(15);
	max-width: 100%;
	width: em(1000);
	margin: 0 auto;
	position: relative;
	@include clearfix;
	
	@include bp(mob-xl) {
		padding: 0 em(15,$fzMob);
	}
}

%contentwrapper {
	padding: 0 em($contentPad);
	
	@include bp(mob-xl) {
		padding: 0 em(60);
	}
}

%editorLink{
	color: inherit;
	text-decoration: none;
    vertical-align: bottom;
    border-bottom: 1px dotted;
	text-shadow: 0 0 0 rgba(black,0);
	font-weight: normal;
	@include transition(border 0.2s);
	
	&:hover {
		color: inherit;
		border-bottom: 1px dotted transparent;	
	}
}