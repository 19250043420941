

//
// Resets
// --------------------------------------------------


html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
	margin:0; 
	padding:0; 
	border:0; 
	outline:0; 
	font-size:100%; 
	vertical-align:baseline; 
	background:transparent;
}

body {
	line-height:1;
}

ol, 
ul{
	list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main{
	display:block;
}

blockquote,
q {
	quotes:none;
}

// Quotes
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}

a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}

img {
	-ms-interpolation-mode: bicubic;
}

del {
	text-decoration: line-through;
}

abbr[title], 
dfn[title] {
	border-bottom:1px dotted;
	cursor:help;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

input,
select { 
	vertical-align:middle;
}

ins {
	background-color:#ff9; 
	color:#000; 
	text-decoration:none;
}

mark {
	background-color:#ff9;
	color:#000; 
	font-style:italic; 
	font-weight:bold;
}

// Form Resets

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}