

$module: 'hero';

.#{$module} {
	color: white;
	text-align: center;
	padding: em(100) 0 em(140);
		
	@include bp(tab-s-b) {
		padding: em(70) em(20);
	}
	
	.theme-red & {
		background-color: $colorPrimary;
	}

	.theme-yellow & {
		background-color: $color2;
	}
	
	.theme-aqua & {
		background-color: $color4;
	}
	
	.theme-blue & {
		background-color: $colorSecondary;
	}
	
	&__content {
		margin: em(140) 0 0;
		
		@include bp(tab-s-b) {
			margin: em(70) 0 0;
		}
	}
	
	&__copy {
		$fz: 20;
		font-size: em($fz);
		font-family: $ffCopy;
		max-width: em(600,$fz);
		margin: auto;
		
		.theme-white & {
			color: $color3;
		}
	}
	
	&__service-icon {
		margin-bottom: em(140);
		
		& .svg-icon {
			margin: 0 auto;
			height: em(350);
			width: em(350);
		}
	}
	
	&__service-categories {
		margin-top: em(100);
	}
	
	&__service-category {
		display: inline-block;
		padding: 0 em(20);
		text-transform: uppercase;
		font-weight: $fwBold;
		
		&:not(:first-of-type) {
			border-left: em(4) solid;
			
			@include bp(mob-m) {
				border: none;
			}
		}
	}
	
	&__btn-wrap {
		margin-top: em(100);
	}
}