

//
// Media Queries
// --------------------------------------------------


$minBreakpoints: (
  small: 767px,
  medium: 992px,
  large: 1200px,
  extra-large: 1920px
);

$maxBreakpoints: (
  // namespacing inbetween sizes: alphabetised modifiers, indented below parent to visualise relationship.
  
  // DESKTOP
  desk-xxl: 1700px,
  desk-xl: 1600px,
  // desk-xl-a: 1550px,
  // desk-xl-b: 1525px,
  desk-l: 1500px,
  	desk-l-b: 1400px,
  	desk-l-c: 1400px,
  desk-m: 1350px,
  	desk-m-a: 1300px,
  desk-s: 1250px,
  
  // TABLET
  tab-xl: 1200px,
  	tab-xl-a: 1150px,
  	tab-xl-b: 1100px,
  	tab-xl-c: 1050px,
  tab-l: 1000px,
  	tab-l-a: 950px,
  	tab-l-b: 900px,
  tab-m: 850px,
  tab-s: 800px,
  	tab-s-a: 750px,
  	tab-s-b: 700px,
  	tab-s-c: 650px,
  	
  // MOBILE
  mob-xl: 600px,
  	mob-xl-a: 550px,
  mob-l: 500px,
  	mob-l-a: 450px,
  mob-m: 400px,
  	mob-m-a: 350px,
  mob-s: 320px,
);

@mixin bp($breakpoint) { 
  @if map-has-key($minBreakpoints, $breakpoint) {
  	$point: strip-units(map-get($minBreakpoints, $breakpoint)) / 16 +em;
    @media (min-width: #{$point}) {
      @content;
    }
  }
  @if map-has-key($maxBreakpoints, $breakpoint) {
  	$point: strip-units(map-get($maxBreakpoints, $breakpoint)) / 16 +em;
    @media (max-width: #{$point}) {
      @content;
    }
  }
}


@include debugmessages($minBreakpoints);
@include debugmessages($maxBreakpoints,backwards);