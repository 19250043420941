

@include keyframes(phoneAnimation) {
	0% { @include transform(translateY(0)); }
	100% { @include transform(translateY(-100%)); }
}

$module: 		'phone';

.#{$module} {
	&--1, &--2, &--3 {
		position: absolute;
		width: em(259);
		overflow: hidden;
	}
	
	&--1 {
		@include transform(rotate(45deg));
		top: 50%;
		margin-top: em(-170);
		left: 50%;
		margin-left: em(-350);
		
		.#{$module}__group--3 & {
			margin-top: em(-260);
		}
		
		.#{$module}__group--2 & {
			@include bp(mob-xl) {
				left: 55%;
			}
		}
		
		@include bp(tab-s-b) {
			margin: 0;
			@include transform(rotate(45deg)translate(-49%,-27%));
			transform-origin: top;
			top: 50%; left: 50%;
			width: 42%;
		}
	}
	
	&--2 {
		@include transform(rotate(-45deg));
		top: 50%;
		margin-top: em(-350);
		left: 50%;
		
		.#{$module}__group--3 & {
			margin-top: em(-440);
		}
		
		.#{$module}__group--2 & {
			@include bp(mob-xl) {
				left: 55%;
			}
		}
		
		@include bp(tab-s-b) {
			display: none;
		}
	}
	
	&--3 {
		@include transform(rotate(-45deg));
		top: 50%;
		margin-top: em(10);
		left: 50%;
		margin-left: em(-2);
		
		.#{$module}__group--3 & {
			margin-top: em(-62);
		}
		
		@include bp(tab-s-b) {
			display: none;
		}
	}
	
	&__inner {
		.#{$module}__group--2 & {
			height: em(642);
		}
		
		.#{$module}__group--3 & {
			height: em(820);
		}
		
		@include bp(desk-xl) {
			font-size: em(16);
		}
		
		@include bp(desk-l) {
			font-size: em(14);
		}
		
		@include bp(desk-m-a) {
			font-size: em(12);
		}
		
		@include bp(tab-l) {
			font-size: em(10);
		}
		
		@include bp(mob-m) {
			font-size: em(8);
		}
	}
	
	&__content {
		position: absolute;
		top: 15%;
		left: 11%;
		right: 15%;
		margin-right: em(-20);
		bottom: 15%;
		overflow: hidden;
	}
	
	&__content-inner {
		display: block;
		@include transition-property(transform);
		@include transition-duration(5s);
		@include transition-timing-function(ease-in);
		
		.#{$module}--2 & { @include transition-delay(2.5s); }
		.#{$module}--3 & { @include transition-delay(5s); }
		
		.animating & {}
	}
	
	&__pic {
		display: block;
		position: relative;
		pointer-events: none;
	}
	
	&__replay {
		position: relative;
		padding: em(6) em(20) em(6) em(46);
		border-radius: 25px;
		border: 2px solid $color1;
		background-color: transparent;
		color: $color1;
		@include transition-property(background-color, color);
		@include transition-duration(0.2s);
		@include transition-timing-function(ease-in);
		
		&:hover {
			color: white;
			background-color: $color1;
		}
	}
	
	&__replay-icon {
		width: em(14);
	    height: em(16);
	    position: absolute;
	    left: em(17);
	    top: 50%;
	    @include transform(translateY(-50%));
	}
}