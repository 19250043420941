

%pancake {
	position: relative;
	width: em(25);
	cursor: pointer;
	z-index: 100;
	
	&__lines {
		display: block;
		width: 2.5rem;
		height: 0.5rem;
		color: white;
		background-color: currentColor;
		border-radius: 4px;
		@include transition(all 0.2s linear);
		@include transform-origin(50% 50%);
		position: relative;
		pointer-events: none;
		
		.theme-white & {
			color: $colorPrimary;
		}
	}
	
	&__lines:before, &__lines:after {
		content: "";
		position: absolute;			
		display: block;
		width: 2.5rem;
		height: 0.5rem;
		background-color: currentColor;
		border-radius: 4px;
		@include transition(all 0.2s linear);
		@include transform-origin(center);
		pointer-events: none;
		
		.nav-fixed & {
			background-color: $colorPrimary;
		}
	}
	
	&__lines:before {
		top: -1rem;
		
		.active & {
			top: 0;
			@include transform(rotate3d(0,0,1,-45deg));
		}
	}
	
	&__lines:after {
		top: 1rem;
		
		.active & {
			top: 0;
			@include transform(rotate3d(0,0,1,45deg));
		}
	}
}