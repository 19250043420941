//
// Variables
// --------------------------------------------------

// Set debug flag on the preview site for development
$DEBUG: false;
$respPreview: false;

// animation

	$transitionTime: 		0.5s;
	$transitionTime2: 		1s;


// Typography
// -------------------------
	
	
	// weights	
	$fwBlack: 				900;
	$fwBold: 				700;
	$fwSemibold: 			600;
	$fwRegular: 			400;
	$fwLight: 				300;
	$fwSuperlight: 			200;
	
	
	// family
	$ffBase: 			'Source Sans Pro', Arial, sans-serif;
	$ffSecondary: 		"utopia-std",serif;
	$ffHeadings: 		$ffBase;
	$ffCopy: 			$ffSecondary;
	
	
	// style
	$fzBase: 					20 !default;
	$fzMob: 					16;
	$lhBase: 					28 !default;
	$fwBase: 					$fwRegular;
	$fwHeading:  				$fwBase;


// Colors
// -----------------------------------------------------------------------

	$color1: 					#e94b5a; 			// Red			
	$color2: 					#f8ab07; 			// Yellow		
	$color3:					#16345f; 		// Blue	
	$color4: 					#66c1be; 			// Turquoise
	$color5: 					#ebebeb;				// Gray
	$color6: 					#dedede;				// Dark Gray - currently unused

	$color1light:				#ee7677;				// Light Red
	$color2light: 				#fcc964; 				// Light Yellow
	$color3light:				#404e76;				// Light Blue
	$color4light: 				#8ececb;				// Light Turquoise
	
	$color4dark:				#58a9a7;				// Dark Turquoise
	
// Color roles
// ----------------

	$colorPrimary: 				$color1;
	$colorSecondary: 			$color3;
	$colorBody: 				white !default;
	$colorText: 				black !default;
	
	// states
	
	$colorActive: 				$color3;
	$colorHover: 				$color2;
	
	// themes

// Headings
// -------------------------

	$headings: 							'.alpha','.beta','.gamma','.delta','.epsilon','.zeta';
	$headingSizes: 						100,60,30,20,16,16;
	$headingSmallest: 					60,22,32,20,18,16;
	$headingFamilies: 					$ffHeadings, $ffHeadings, $ffHeadings, $ffHeadings, $ffHeadings, $ffHeadings;
	$headingColors: 					inherit , inherit, inherit, inherit, inherit, inherit;
	$headingMargins: 					50,40,20,20,18,16;
	$headingLineHeights: 				100,60,30,20,16,16;
	
// Other
// -------------------------

	$inout: 							cubic-bezier(0.18, 0.89, 0.32, 1.28);
	$contentPad: 						130; // Pads from 'outside > in' on inner wrapper of TwoCol layout
	$innerPad: 							160; // Pads from 'middle > out' on columns in TwoCol layout
	$boxShadow: 						0 em(10) em(15) rgba(black,0.4);
	$insetShadow: 						0 em(60) em(80) em(-30) rgba(black,0.4);
	$insetShadowHover: 					0 em(20) em(60) em(-10) rgba(black,0.4);