

// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)
@function em($pxval, $base: $fzBase) {
  @return ($pxval / $base) * 1em;
}

// Font sizing using rems with a fallback to px in unsupported
// Font-size of 62.5% must be set on body
@mixin font-size($sizeValue: 16){
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 10) + rem;
}

$debugColors: (MEDIUMORCHID,NAVY,SLATEGRAY,GREENYELLOW,INDIGO,DARKSEAGREEN,DARKGREEN,DARKRED,MEDIUMSLATEBLUE,MEDIUMORCHID,HOTPINK,LIGHTSTEELBLUE,DARKOLIVEGREEN,LIGHTSEAGREEN,DEEPPINK,PERU,GOLDENROD,FIREBRICK,FORESTGREEN,LIGHTCORAL,DARKSLATEGRAY,TAN,KHAKI,MEDIUMPURPLE);

@mixin debugmessage($message, $color: rgba(255,255,0, 0.9) )
	{ body::before{ content: $message; 
		font-weight: normal;
		font-family: Arial;
		display: block;
		text-align: center;
		background-color: #{$color}; 
		position: absolute; 
		right: 0;
		font-size:20px; 
		left: 0; 
		top: 0; 
		z-index: 99;
		color: white;
	} 
}

@mixin debugmessages($map,$direction: forwards) {
	@if($DEBUG == true){
		@if($direction == backwards){
			$i: length($debugColors) !global;
		}
		@if($direction == forwards){
			$i: 1 !global;
		}
		
		@each $name, $width in $map {
			@include bp($name){
				
				@include debugmessage("#{$name}: #{$width}",nth($debugColors,$i));
				@if($direction == backwards){
					$i: $i - 1;
				}
				@if($direction == forwards){
					$i: $i + 1;
				}
				
			}
		}
	}	
}

/* Flexible Properties
*
* @include fp(font-size, 50, 100); //50px at 320, 100px at 1920;
*
*/

@mixin fp($property, $min, $max, $start: 320, $end: 1920, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $adder: ($adder/10)+rem;
  $formula: calc(#{$multiplier + 0vw} + #{$adder});
  @if $clip and $clipAtStart {
    @media (max-width: ($start/16)+em) {
      #{$property}: ($min/10)+rem;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: ($end/16)+em) {
      #{$property}: ($max/10)+rem;
    }
  }
  #{$property}: $formula;
}


@mixin screenshotsGallery() {
	height: em(600);
	margin: 0 em(25);
		
	@include bp(desk-xl) {
		height: em(550);
	}
	
	@include bp(desk-l) {
		height: em(500);
		margin: 0 em(15);
	}
	
	@include bp(desk-m) {
		height: em(450);
	}
	
	@include bp(tab-m) {
		height: em(350);
		margin: 0 em(10);
	}
	
	@include bp(mob-xl) {
		height: em(300);
	}
	
	@include bp(mob-l-a) {
		height: em(250);
	}
}

@mixin pushDown() {
	box-shadow: $insetShadow;
	@include transition(
		box-shadow 0.5s,
		transform 0.5s);
	
	&:hover {
		box-shadow: $insetShadowHover;
		transform: scale(0.99);
	}
}