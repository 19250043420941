

$module: 'transition';

.#{$module} {
	position: relative;
	visibility: hidden;

	&.slideIn, &.slideOut {
		visibility: visible;
	}

	&--top, &--bottom {
		position: fixed;	
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	  	@include animation-iteration-count(1);
	  	@include animation-timing-function(ease-in);
	  	@include transition(background-color 0.5s linear);
	}

	&--top {
		background-color: $colorPrimary;
		z-index: 1;
		@include transform (translate3d(0,100%,0));
	  	@include animation-duration($transitionTime);	  	
	  	
	  	.theme-yellow & { background-color: $color1; }
		.theme-blue & { background-color: $colorSecondary; }
		.theme-aqua & { background-color: $color2; }
		.theme-red & { background-color: $color3; }
		.theme-white & { background-color: $color4; }

		.slideIn & {
			@include animation-name(slideUp);
			@include animation-fill-mode(forwards);
		}
		
		.slideOut & {
			@include animation-timing-function(ease-out);
			@include animation-duration(0.5s);
			@include animation-name(slideOut);
			@include animation-fill-mode(forwards);
		}
	}

	&--bottom {
		background-color: $colorSecondary;
		z-index: 20;
		@include transform (translate3d(0,100%,0));
		@include animation-duration($transitionTime2);
		
		.theme-yellow & { background-color: $color4; }
		.theme-blue & { background-color: $colorSecondary; }
		.theme-aqua & { background-color: $color2; }
		.theme-red & { background-color: $color2; }
		.theme-white & { background-color: $color3; }

		.slideIn & {
			@include animation-name(slideUp);
			@include animation-fill-mode(forwards);
		}
		
		.slideOut & {
			@include animation-timing-function(ease-out);
			@include animation-duration(0.5s);
			@include animation-name(slideOut);
			@include animation-fill-mode(forwards);
		}
	}
}