


$module: 'hover-svg';

.#{$module} {
	cursor: pointer;	
	width: 100%;
	text-align: center;
	@include flex-grow(1);
	@include display(flex);
	@include align-items(center);
	@include justify-content(center);
	padding: 0 em(10);
	
	background-color: transparent;
	border: none;
	
	&:focus {
		outline: none;
	}

	&__item {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		z-index: 1;
		pointer-events: none;
		
		> path {
			@include transform (translateY(100%));
			@include transition (transform 0.5s ease-in);

			.#{$module}:hover & {
				@include transform (translateY(0));
				@include transition (transform 0.01s ease-in);
			}	
		}
	}

	&__title {
		position: relative;
		z-index: 2;

		.active & {
			color: white;
		}
	}

	&__content {
		text-align: center;
		color: $color1;
		z-index: 5;
		@include transition (fill 0.2s ease-in 0.15s);
		position: relative;
		
		.#{$module}:hover & {
			color: white;
		}
		
		@include bp(tab-xl-a) {
			font-size: 2rem;
		}
	}

	&__logo {
		position: absolute;
	    top: 50%;
	    left: 50%;
	    @include transform (translate3d(-50%, -50%, 0));
	    padding: em(10);
	    z-index: 3;
	    pointer-events: none;
	    
	    .#{$module}:hover & {
	    	.rs-logo-1, .rs-logo-small-3 {
	    		fill: white;
	    		@include transition (fill 0.2s ease-in 0.15s);
	    	}
	    }
	}
	
	&__logo--desktop {
		@extend .#{$module}__logo;
		
		@include bp(tab-xl-a) {
			display: none;
		}
	}
	
	&__logo--mobile {
		@extend .#{$module}__logo;
		display: none;
		
		@include bp(tab-xl-a) {
			display: block;
		}
	}
}