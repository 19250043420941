

$module:		 'cursor';


.#{$module} {
	position: fixed;
	opacity: 1;
	z-index: 100;
	
	pointer-events: none;
	
	&__handler.hidden {
		display: none;
	}
	
	&__ripple {
		position: fixed;
		display: block;
		z-index: 999999;
		pointer-events: none;
	}
	
	&__ripple {
		width: em(14);
		height: em(14);
		background-color: rgba(black,0.5);
		border-radius: 50%;
		visibility: hidden;
		position: absolute;
		@include transition(width 0.2s, height 0.2s, background-color 0.8s);
	}
	
	&__circle {
		position: absolute;
		top: 0;
		left: 0;
		height: em(14);
		width: em(14);
		background-color: rgba(black,0.3);
		border-radius: 50%;
		@include transform(translate(-50%,-50%));
		@include transition(width 0.2s, height 0.2s);
	}
	
	&.hidden &__circle {
		height: em(0);
		width: em(0);
	}
	
	&.hover &__circle {
		height: em(64);
		width: em(64);
		background-color: rgba(black,0.2);
		
		&:before {
			content: '';
			display: block;
			background-color: rgba(white,0.3);
			height: em(5);
			width: em(5);
			border-radius: 50%;
			position: absolute;
			top: 48%;
			left: 48%;
		}
	}

	&.input &__circle {
		height: em(32);
		width: em(4);
		border-radius: em(2);
		background-color: rgba(black,0.2);
	}	
	
	&.flickity {
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));
			content: 'DRAG';
			color: white;
			font-weight: 800;
			font-size: em(14);
			line-height: 0;
		}
	}
	
	&.flickity &__circle {
		height: em(64);
		width: em(64);
	}
	
	&__ripple.pulse {
		visibility: visible;
		@include animation-name(rippleIt);
		@include animation-duration(0.8s);
		@include transform(translate(-50%,-50%));
	}
}

@keyframes rippleIt {
	from {width: em(14);
		height: em(14);}
	to {height: em(64);
		width: em(64);;
		background-color: transparent;
	}
}