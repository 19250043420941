

$module: 		'content';


.#{$module} {

	&__section {
		margin: 0 em(20) em(140);
		color: $colorSecondary;
		
		&:first-of-type {
			margin: em(140) em(20);
		}
		
		& .beta {
			text-align: center;
			text-transform: none;
		}
	}
	
	&__copy {
		max-width: em(600);
		margin: 0 auto em(50);
		font-family: $ffSecondary;
		position: relative;
	}
	
	&__copy--center {
		max-width: em(600);
		margin: 0 auto em(50);
		font-family: $ffSecondary;
		text-align: center;
	}
	
	&__copy--large {
		$fz: 30;
		font-size: em($fz);
		max-width: em(600,$fz);
		margin: 0 auto em(50);
		font-family: $ffSecondary;
	}
}