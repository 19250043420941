

$module: 		'big-clients';

.#{$module} {
	color: white;
	text-align: center;
	background-color: $colorPrimary;
	padding: em(75);
	
	@include bp(tab-xl) {
		padding: em(25);
	}
	
	.theme-yellow & {
		background-color: $color2;
	}
	
	&__items {
		@include display(flex);
		@include flex-flow(row wrap);
		@include align-items(center);
		@include justify-content(center);
		
		> li {
			display: inline-block;
			vertical-align: middle;
			@include display(flex);
			@include align-items(center);
			margin: em(25) em(50) em(50);
			width: em(125);
			max-height: em(125);
		}
		
		@include bp(mob-xl) {
			> li {
				width: em(75);
				margin: em(20);
			}
		}
	}
	
	&__logo {
		display: block;
		max-height: 100%;
		max-width: 100%;
	}
}