

$module: 'form';

.#{$module} {
    max-width: em(900);
    padding-bottom: em(50);
	list-style: none;
	overflow: hidden;
	pointer-events: all;
	// NEED TO DEFINE A WIDTH ON CONTAINING ELEMENT
	
	&__control {
		$fontSize: 22;
		display: block;
		margin: 0 0 em(50,$fontSize) 0;
		
		.flex {
			overflow: hidden;
			display: block;
			position: relative;
		}
		
		@include bp(tab-l-b) {
			margin: 0 0 em(20,$fontSize);
		}
	}
	
	&__label {
		$fontSize: 16;
		font-size: em($fontSize);
		font-weight: $fwBold;
		line-height: 1;
		font-family: $ffBase;
		text-align: left;
		text-transform: capitalize;
		width: 100%;
		display: block;
		margin: 0 0 em(10,$fontSize) em(25,$fontSize);
	}
	
	&__input {
		height: em(70);
		width: 100%;
		display: block;
		border: none;
		padding: 0 0 0 em(25);
		color: $colorSecondary;
		border-radius: em(20);
		background-color: rgba($colorSecondary,0.2);
		font-family: $ffSecondary;
		@include transition(background-color 0.2s);
		
		&:focus {
			background-color: rgba($colorSecondary,0.05);
			outline: none;
		}
		
		&.dirty:invalid {
			color: $colorPrimary;
			background-color: rgba($colorPrimary,0.5);
		}
		
		&.dirty:focus:invalid {
			background-color: rgba($colorPrimary,0.2);
		}
	}
	
	&__btn-centered {
		text-align: center;
	}
	
	&__select {
		$fontSize: 22;
		font-size: em($fontSize);
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 0;
		background-color: white;
		padding-left: em(5,$fontSize);
		
		@include bp(mob-l) {
			padding-left: em(17,$fontSize);
		}
		
		/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
		/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
			&::-ms-expand {
				display: none;
			}
			
			padding-right: 2em; /* Match-01 */
			border: 1px solid transparent; /* Match-05 */
				
			&::after {
				display: block;
			}
		}
	}
	
	&__error {
		$fontSize: 16;
    	font-size: em($fontSize);
		color: $colorPrimary;
		display: block;
		margin: em(10) em(25) 0;
	}
	
	&__textarea {
		@extend .#{$module}__input;
		resize: vertical;
	    height: em(280);
	    padding: em(25);
		
		@include bp(tab-l-b) {
			height: em(120);
			margin: 0 0 em(35);
		}
	}
	
	&__success {
		color: $color1;
		font-family: $ffBase;
	}
	
	&__success-sub {
		$fontSize: 30;
		font-size: em($fontSize);
		@include fp(font-size,22,$fontSize,600);
		font-weight: $fwBold;
	}
}