

$module: 'contact-us';

.#{$module} {
	color: white;
	text-transform: none;
	@include display(flex);
	@include justify-content(center);
	margin: 0 0 em(42);
	
	@include bp(mob-xl) {
		@include flex-direction(column);
		@include align-items(center);
		
		@include bp(mob-xl) {
			@include align-items(flex-start);
		}
	}
	
	&__col {
		$fontSize: 16;
		font-size: em($fontSize);
		text-align: left;
		padding: 0 0 0 em(75);
		
		&:first-child {
			padding: 0 em(75) 0 0;
		}
		
		@include bp(mob-xl) {
			padding: em(10) 0;
			
			&:first-child {
				padding: em(10) 0;
			}
		}
	}
}