
$module: 'parallax-card';

.#{$module} {
	
	margin: em(50) 0;
	
	@include bp(tab-l) {
		margin: em(20) em(10) 0;
	}
	
	@include bp(mob-xl) {
		margin: em(20) em(20) 0;
	}
	
	&__shiny, &__content {
		@include backface-visibility(hidden);
		@include transform-style(preserve-3d);
		@include transform(translate3d(0,0,0));
	}
	
	&__items {
		@include display(flex);
		@include flex-flow(row wrap);
		@include align-items(space-between);
	}
	
	&__item {
		display: block;
		width: 25%;
		order: 0;
		
		&.inactive {
			order: 1;			
			opacity: 0.3;
		}
		
		&:hover {
			z-index: 29;
		}
	}
	
	&__item--big {
		@extend .#{$module}__item;
		width: 50%;
		
		&:hover {
			z-index: 29;
		}
	}
	
	&__item, &__item--big {
		@include bp(desk-xl) {
			width: calc(100%/3);
		}
		
		@include bp(tab-l) {
			width: 50%;
		}
		
		@include bp(mob-xl) {
			width: 100%;
		}
	}
	
	&__container {
		display: block;
		position: relative;
		perspective: em(400);
		margin: em(10);
		cursor: pointer;
		
/* 		@include bp(tab-l) {
	margin: em(10) 0;
} */
		
		@include bp(mob-xl) {
			margin: em(10) 0;
		}
	}

	&__wrapper {
		position: relative;
		perspective: em(400);
		height: em(406);
		@include transition-property(transform);
		@include transition-duration(0.3s);
		@include transition-timing-function($inout);

		.#{$module}__item:hover & {
			@include transform(translateZ(em(-15)));
		}
		
		@include bp(tab-l) {
			height: em(203);
		}
	}

	&__bg, &__shiny, &__content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__bg {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: em(20);
		
		box-shadow: $insetShadow;
		@include transition(box-shadow 0.5s);
		
		.#{$module}__item:hover & {
			box-shadow: $insetShadowHover;
		}
	}

	&__shiny {
		opacity: 0;
		border-radius: em(20);
		@include transition(opacity 0.2s ease-in);

		.#{$module}__item:hover & {
			opacity: 0.8;
		}
	}

	&__content {
		color: white;
		pointer-events: none;
		@include transform(translateY(100%));
		@include transition-property(transform);
		@include transition-duration(0.4s);
		@include transition-timing-function(ease-in);

		.#{$module}__item:hover & {
			@include transform(translateY(0));
		}
	}
	
	&__content-anim {
	    height: 100%;
	    @include display(flex);
	    @include flex-direction(column);
	    @include justify-content(space-between);
	    padding: em(25) em(10);
	}

	&__inner {
		opacity: 0;
	    padding: 0 25% 0 0;
		transition: opacity 0.0000001s ease-in;
		@include transition(opacity 0.0000001s ease-in);

		.#{$module}__item:hover & {
			opacity: 1;
			@include transition(opacity 0.4s 0.4s ease-in);
		}
		
		@include bp(tab-l) {
			display: none;
		}
	}
	
	&__home-info {
		@include transform(translateY(-100%) translateY(-9.5rem));
		@include transition-property(transform);
		@include transition-duration(0.4s);
		@include transition-timing-function(ease-in);
		
		.#{$module}__item:hover & {
			@include transform(none);
		}
	}

	&__title {
		$fontSize: 50;
		font-size: em($fontSize);
		font-weight: $fwBold;
		line-height: (40/$fontSize);
		text-transform: capitalise;
		text-shadow: em(3,$fontSize) em(3,$fontSize) 0 rgba($colorSecondary,0.3);
		@include bp(tab-l) {
			font-size: em(40);
		}
	}
	
	&__copy {
		$fontSize: 22;
		font-size: em($fontSize);
		font-family: $ffSecondary;
	    margin: 0 0 em(15);
	    line-height: 1.2;
	}
	
	&__arrow {
	    position: absolute;
	    bottom: em(50);
	    right: em(50);
	    opacity: 0;
	    
	    .#{$module}__item:hover & {
			opacity: 1;
			@include transition(opacity 0.4s 0.4s ease-in);
		}
		
		@include bp(tab-l) {
			bottom: em(10);
			right: em(10);
		}
	}
}