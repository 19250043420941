

$module: 		'work';

.#{$module} {
	&__filter-wrap {
		padding-bottom: em(70);
		background-color: $color2;
		text-align: center;
	}
}