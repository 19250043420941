

@include keyframes(slideUp) {
	0% { @include transform(translateY(100%)translateZ(0)); }
	100% { @include transform(translateY(0)translateZ(0)); }
}

@include keyframes(slideOut) {
	0% { 
		@include transform(translateY(0)translateZ(0)); 
	}
	100% { 
		@include transform(translateY(-100%)translateZ(0)); 
	}
}

@include keyframes(slideUpFade) {
	0% {
		opacity: 1;
		@include transform(translateY(0)translateZ(0));
	}
	
	100% {
		opacity: 0;
		@include transform(translateY(-20%)translateZ(0));
	}
}