

$module: 		'header';


.#{$module} {
	padding: em(22) em($contentPad) em(50);
	@include display(flex);
	@include align-items(center);
	@include justify-content(space-between);
	height: em(110);
	
	@include bp(tab-xl) {
		padding: em(22) em(40) em(50);
	}
	
	.theme-yellow & {
		background-color: $color2;
	}
	
	.theme-red & {
		background-color: $colorPrimary;
	}
	
	.theme-blue & {
		background-color: $colorSecondary;
	}
	
	.theme-aqua & {
		background-color: $color4;
	}
	
	&__logo {
		@include flex(0 0 em(140))
		color: white;
	}
	
	&__logo--white {
		@extend .#{$module}__logo;
	}
	
	&__logo-anchor {
		display: block;
		@include transition(color 0.2s);
		
		&:hover {
			color: white;
		}
		
		> svg {
			width: em(140);
			height: em(42);
		}
		
		.active & {
			color: $colorPrimary;
			position: fixed;
			top: em(20);
			z-index: 999;
		}
		
		.theme-blue .active & {
			color: $colorSecondary;
		}
		
		.active .#{$module}__logo--white & {
			color: $colorSecondary;
		}
		
		.active &:hover {
			color: $colorPrimary;
		}
		
		.theme-white & {
			color: $colorPrimary;
			
			&:hover {
				color: $colorPrimary;
			}
		}
		
	}
	
	&__pancake {
	    @extend %pancake;
	    @include transition(opacity 0.15s linear);
	    @include display(flex);
	    @include flex(0 0 em(140));
	    @include justify-content(flex-end);
	    height: 100%;
	    
	    @include bp(mob-l-a) {
	    	@include flex(0 0 auto);
	    }
		
		.no-flex & {
			float: right;	
		}
	}
	
	&__fixed-wrap {
		background: none;
		border: none;
		height: em(40);
		width: em(40);
		
		&.active {
			position: fixed;
			top: em(23);
		}
		
		&:focus {
			outline: none;
			color: $colorPrimary;
		}
	}
	
	&__pancake-lines {
		@extend %pancake__lines;
		transition: all 0.4s;
		
		.active & {
			color: transparent;
		}
		
		.active &:before,
		.active &:after {
			color: $colorPrimary;
			transition: all 0.2s;
		}
		
		@include bp(mob-xl) {
	    	.active & {
	    		background-color: transparent;
	    		color: white;
	    	}
	    }
	    
	    .#{$module}__fixed-wrap:hover &,
	    .#{$module}__fixed-wrap:hover &:before,
	    .#{$module}__fixed-wrap:hover &:after {
	    	box-shadow: em(2) em(2) 0 0 rgba($colorSecondary,0.3);
	    }
	    
	    .#{$module}__fixed-wrap.active:hover &,
	    .#{$module}__fixed-wrap.active:hover &:before,
	    .#{$module}__fixed-wrap.active:hover &:after {
	    	box-shadow: none;
	    }
	}
	
	&__title {
		@include flex(0 1 auto)
		text-align: center;
		$fz: 16;
		position: relative;
		font-size: em($fz);
	    font-weight: $fwBold;
	    text-transform: uppercase;
	    color: white;
	    
	    @include bp(mob-xl) {
	    	display: none;
	    }
	}
	
	.theme-white &__title {
		color: $colorSecondary;
	}
	
	
	&__title--link {
		@extend .#{$module}__title;
		color: white;
		transition: text-shadow 0.2s;
		@include transition(transform 0.2s);
		
		&:hover {
	    	color: white;
	    	text-shadow: em(2) em(2) 0 rgba(white,0.3);
	    	@include transform(translateX(50%));
	    	
	    	@include bp(mob-xl) {
	    		@include transform(translateX(0%));
	    	}
	    }
	    
	    &:before {
	    	@include transition(all 0.2s);
	    	position: absolute;
	    	content: 'back to';
	    	left: 0;
	    	max-width: 0;
	    	max-height: 100%;
	    	overflow: hidden;
	    	display: block;
	    	white-space: nowrap;
	    }
	    
	    &:hover:before {
	    	$fz: 16;
	    	max-width: em(67,$fz);
	    	left: em(-66,$fz);
	    	padding-right: em(5);
	    }
	    
	    @include bp(mob-xl) {
	    	display: block;
	    	position: absolute;
	    	top: em(110);
	    	left: 50%;
	    	transform: translateX(-50%);
	    }
	    
	    @include bp(mob-xl) {
	    	display: block;
	    	position: absolute;
	    	top: em(110);
	    	left: 50%;
	    	transform: translateX(-50%);
	    }
	}
}