

$module: 'btn';

.#{$module} {
	@extend %button;
	$fontSize: 16;
	@include font-size($fontSize);
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	border-radius: em(20,$fontSize);
	background-color: transparent;
	padding: em(26,$fontSize) em(30,$fontSize);
	line-height: 1;
	overflow: hidden;	
	width: 30rem;
	max-width: 100%;
	text-align: left;
	box-shadow: 0;
	font-weight: $fwBlack;
	@include transition(box-shadow 0.5s, color 0.2s,transform 0.5s);
	
	&:hover {
		color: white;
		box-shadow: $insetShadowHover;
		@include transform(scale(1.03));
		@include transition(box-shadow 0.5s, color 0.2s, background-color 0.2s 0.4s,transform 0.2s);
	}
	
	&--primary {
		@extend .#{$module};
		border: 2px solid white;
		color: white;
		
		> * {
			pointer-events: none;
		}
		
		&:hover {
			color: $colorPrimary;
			background-color: white;
		}
	}
	
	&--secondary {
		@extend .#{$module};
		border: 2px solid $color3;
		color: $color3;
		
		&:hover {
			background-color: $color3;
		}
	}
	
	&--tertiary {
		@extend .#{$module};
		$fz: 16;
		@include font-size($fz);
		border: 2px solid $colorPrimary;
		color: $colorPrimary;
		font-weight: $fwBlack;
		margin-bottom: em(20,$fz);
		
		&:hover {
			background-color: $colorPrimary;
		}
	}

	&__icon {
		position: absolute;
		right: em(18);
		top: 50%;
		width: em(18);
		@include transform(translateY(-50%));
	}

	&__svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		fill: $color1;

		@include transform (translateY(100%));
		@include transition-property (transform);
		@include transition-duration (0.2s);
		@include transition-timing-function (linear);

		.#{$module}--primary:hover &,
		.#{$module}--secondary:hover &,
		.#{$module}--tertiary:hover & {
			@include transition-duration (0);
			@include transform (translateY(0%));
		}
		
		.#{$module}--primary & {
			fill: white;
		}
		
		.#{$module}--secondary & {
			fill: $color3;
		}
		
		.#{$module}--tertiary & {
			fill: $colorPrimary;
		}
	}

	&__text {
		position: relative;
		z-index: 3;
	}

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__svg, &__svg > path, &__text, 
	&__inner, &__icon {
		pointer-events: none;
	}
}