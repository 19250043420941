

//
// Editorial Macros
// --------------------------------------------------

.editorLargeText {
	font-size: em($fzBase * 1.5);
}

.editorSvg {
	max-width: em(140);
	max-height: em(160);
	margin-bottom: em(20);
}

.editorBigSpace {
	display: block;
	width: 100%;
	height: em(140);
	
	@include bp(mob-xl) {
		height: em(70);
	}
}

// Links
// ---------------------

.editorLink,
.telephone,
.emailLink {
	color: inherit;
	text-decoration: none;
    vertical-align: bottom;
    border-bottom: 1px dotted rgba($color4,0.7);
	font-weight: normal;
	@include transition(border 0.2s);
	
	&:hover {
		color: inherit;
		border-bottom: 1px dotted transparent;	
	}
}

.rteLink {
	@extend .editorLink;
}

// legacy ///////

// lists 


//Columns
.editorColumns {
	width: 100%;
	@include clearfix
}
.editorColumnsInner {
	float: left; 
	
	@include bp(desk-s) {
		width: auto;
		float: none;
	}
}

.editorColumnsPadding{
	padding: em(5);
}


// RTE Images
// -------------------------

.editorImg {
	max-width: 120%;
	margin: em(50) -10%;
	
	@include bp(tab-m) {
		$extraMargin: em(40);
		max-width: calc(100% + #{$extraMargin});
		margin: em(50) em(-20);
	}
}

.editorial .editorImg {
	position: absolute;
	width: auto;
	max-width: none;
	max-height: em(550);
	@include transform(translateY(em(-150)));
	margin: 0;
	
	&:nth-of-type(odd) {
		right: 125%;
		
		@include bp(extra-large) {
			right: 140%;
			max-height: em(700);
		}
		
		@include bp(desk-xxl) {
			max-height: em(450);
		}
		
		@include bp(desk-xl) {
			right: 110%;
		}
		
		@include bp(desk-m-a) {
			@include transform(none);
			right: 0;
			position: relative;
			max-height: none;
			max-width: 120%;
			margin: em(50) -10%;
		}
		
		@include bp(tab-m) {
			$extraMargin: em(40);
			max-width: calc(100% + #{$extraMargin});
			margin: em(50) em(-20);
		}
	}
	
	&:nth-of-type(even) {
		left: 125%;
		
		@include bp(extra-large) {
			left: 140%;
			max-height: em(700);
		}
		
		@include bp(desk-xxl) {
			max-height: em(450);
		}
		
		@include bp(desk-xl) {
			left: 110%;
		}
		
		@include bp(desk-m-a) {
			@include transform(none);
			left: 0;
			position: relative;
			max-height: none;
			max-width: 120%;
			margin: em(50) -10%;
		}
		
		@include bp(tab-m) {
			$extraMargin: em(40);
			max-width: calc(100% + #{$extraMargin});
			margin: em(50) em(-20);
		}
	}
}


// Macro Images
// -------------------------

.imageBox{
	width: 100%;
	text-align: center;
	display: block; 
	clear: both;
	margin: em($lhBase) 0;
	img{
		margin: 0 auto;
	}
}

.imageBoxCaption{
	text-align:left;
	display:block;
	margin:0 auto;
}

// Video
// -------------------------
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-top: em($lhBase);
}

.embed-container iframe, 
.embed-container object, 
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Tables
// -------------------------

.rteCopy table {
	$fontSize: 16;
	background-color: rgba(white,0.6);
	font-size: em($fontSize);
	margin: 0 0 0 em(-80,$fontSize);
	position: relative;
	padding: em(30,$fontSize);
	margin: em(55,$fontSize);
	display: block;

	&:before {
		content: "";
		position: absolute;
		top: em(-60,$fontSize);
		left: em(-30,$fontSize);
		width: 100%;
		height: 100%;
		background-color: white;
		opacity: 0.6;
	}
	
	h1,h2,h3,h4,h5 {
		color: $color4;
		padding: 0 em(10) em(14) em(10);
	}
	
	@include bp(mob-xl) {
		&:before {
			content: none;
		}
	}
}

.rteCopy thead {
	border-bottom: 1px solid $color4;
	position: relative;
	z-index: 2;
}

.rteCopy tbody {
	position: relative;
	z-index: 2;
}

.rteCopy th {
	color: $color4;
	vertical-align: bottom;
	padding: 0 em(10) em(14) em(10);
	position: relative;
	z-index: 2;
}

.rteCopy td {
	padding: em(9) em(12.5) em(20);
	position: relative;
	z-index: 2;
	width: 100%;
}

.rteCopy tbody tr {
	border-bottom: 1px solid $color4;
}

.clearer {
	clear: both;
}